import unescape from 'lodash/unescape';

export type TokenTransformers = {
  name: string;
  arguments?: string[];
};

export type Token = {
  path: string;
  type?: string;
  transformers?: TokenTransformers[];
  isEscaped?: boolean;
};

export const getWfTokenPattern = () => {
  return /{{\s*wf\s*({.*?})\s*}}/g;
};

export const getCatchAllTokenPattern = () => {
  return /{{\s*(.*?)\s*}}/g;
};

export const getExternalTokenPattern = () => {
  return /{\\{(\s*.*?\s*)}}/g;
};

export const getAmountTokenPattern = () => {
  return /{{\s*wf\s*({&quot;path&quot;:&quot;amount&quot;,&quot;type&quot;:&quot;CommercePrice&quot;\\})\s*}}/;
};

/**
 * Similar to `parseTokenJson`, but this function assumes the input is the inner match from `getWfTokenPattern`.
 * E.g. `{...}` in `{{ wf {...} }}`
 */
export function parseTokenJsonFromMatch(match: string): Token | undefined {
  let token: Token;
  let isEscaped = false;
  try {
    const replacedTrailing = match.replace(/\\}/g, '}');
    const unescaped = unescape(replacedTrailing);

    // If the trailing backslash is not present in the original match, or if unescaping the match causes it to be
    // different, then the original match is not escaped
    if (match !== replacedTrailing && unescaped !== replacedTrailing) {
      isEscaped = true;
    }
    token = JSON.parse(unescaped);
  } catch (err) {
    return;
  }
  if (!token?.path || !token.type) {
    return; // If path doesn't exist, this JSON string is not a token
  }
  token.isEscaped = isEscaped;
  return token;
}

/**
 * Takes a token string and parses it to a token object
 * @param  string A token string, e.g. '{{ wf {&quot;path&quot;:&quot;name&quot;\} }}'
 * @returns       A parsed token object or null if string is not a valid token string
 */
export function parseTokenJson(string: string): Token | null {
  if (string.match(getWfTokenPattern())) {
    let token;
    try {
      token = JSON.parse(unescape(extractToken(string).replace(/\\}/g, '}')));
    } catch (err) {
      return null;
    }

    if (!token || !token.path || !token.type) {
      // If path doesn't exist, this JSON string is not a token
      return null;
    } else {
      return token;
    }
  } else {
    return null;
  }
}

export function extractToken(
  string: string,
  {
    shortHand,
  }: {
    shortHand?: boolean;
  } = {}
) {
  return shortHand
    ? string.replace(getCatchAllTokenPattern(), (match, subMatch) => {
        return stripLegacyShorthandSuffix(subMatch);
      })
    : string.replace(getWfTokenPattern(), '$1');
}

/**
 * Takes a legacy shorthand token path and strips
 * suffices that we used to have in ImageRef and Option tokens
 *
 * This is needed for the legacy bindings:
 * Option: {{ option:name }} and {{ author:option.name }}
 * ImageRef: {{ image.url }} and {{ author:image.url }}
 */
export function stripLegacyShorthandSuffix(tokenPath: string) {
  return tokenPath
    .split(':')
    .map((part) => part.split('.')[0])
    .join(':');
}
